/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'node-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 13a5 5 0 10-4.975-5.5H4A1.5 1.5 0 002.5 6h-1A1.5 1.5 0 000 7.5v1A1.5 1.5 0 001.5 10h1A1.5 1.5 0 004 8.5h2.025A5 5 0 0011 13m.5-7.5v2h2a.5.5 0 010 1h-2v2a.5.5 0 01-1 0v-2h-2a.5.5 0 010-1h2v-2a.5.5 0 011 0"/>',
    },
});
